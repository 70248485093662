import React, { useState } from 'react';
import { GameContext, Events } from '../game/gamecontext';
import { Action, GameState, TurnState } from '../game/gamestate';
import { Moves } from '../game/moves';
import { Grid, Typography, CssBaseline, Button, Box } from '@material-ui/core';


const BOARD_WIDTH = 500;
const CENTER_TO_STAR = 65;
export const COLORS = ["green", "orange", "purple", "gray", "red", "blue"];
export const WHITE = "white";

interface GameActionPayload {
  type: string;
  args: any;
  playerID: string;
}
interface GameAction {
  type: string;
  payload: GameActionPayload;
}
interface GameLog {
  action: GameAction;
  turn: number;
}

interface BoardProps {
  ctx: GameContext;
  G: GameState;
  moves: Moves;
  events: Events;
  isActive: boolean;
  playerID: string;
  log: GameLog[];
}

const verbs: string[] = [
  "rolled",
  "moved",
  "couldn't move",
  "took out",
  "struggled a bit",
  "won",
];

function nameOfPlayer(G: GameState, ctx: GameContext, playerID: string): string {
  console.log("nameOfPlayer", playerID, ctx.playOrder.indexOf(playerID));
  if ( ctx.playOrder.indexOf(playerID) !== null && ctx.playOrder.indexOf(playerID) >= 0 && ctx.playOrder.indexOf(playerID) < ctx.playOrder.length ) {
    return COLORS[G.players[ctx.playOrder.indexOf(playerID)].idx];
  } else {
    return "someone";
  }
}


const Board: React.FC<BoardProps> = ({ ctx, G, moves, events, isActive, playerID, log }) => {

  const [lastClickedId, setLastClickedId] = useState(-1000);

  const currentColor = nameOfPlayer(G, ctx, ctx.currentPlayer);
  const handleClick = (id: number) => {
    // console.log("handleClick with ", id, G.src);
    if (isActive) {
      let lid = id === -1 ? id : (id < -1 ? -2 : id);

      if ( lastClickedId === lid ) {
        console.log("arrived with an identical lastClickedId, ignoring: ", lastClickedId);
        return;
      } else {
        console.log("arrived with a new clickedId: ", lid, lastClickedId);
        setLastClickedId(lid);
      }

      if (G.src === null) {
        moves.chooseSource(lid);
      } else if (G.src !== null) {
        moves.move(G.src, lid);
        setLastClickedId(-1000);
      }
    }
  };

  const localEndTurn = () => {
    if (events.endTurn) {
      events.endTurn();
    }
  }

  const buildControls = () => {

    if (ctx.currentPlayer !== playerID) {
      return (
        <Grid container direction="row" wrap="nowrap">
          <p>waiting on {nameOfPlayer(G,ctx,ctx.currentPlayer)}...</p>
        </Grid>
      );
    }

    if (G.turnState === TurnState.MustRoll) {
      if (G.dieRoll === 6) {
        return (
          <Grid container direction="row" wrap="nowrap"  justify="flex-start">
            <p>Roll again!</p>
            <Box ml="1rem"><Button onClick={() => moves.roll()} variant="contained" color="secondary">Roll!</Button></Box>
          </Grid>);
      } else {
        return (
          <Grid container direction="row" wrap="nowrap"  justify="flex-start">
            <p>Give us a roll!</p>
            <Box ml="1rem"><Button onClick={() => moves.roll()} variant="contained" color="secondary">Roll!</Button></Box>
          </Grid>);
      }
    } else if (G.turnState === TurnState.MustEnd) {
      return (
        <Grid container direction="row" wrap="nowrap"  justify="flex-start">
          <p>You can't use that {G.dieRoll}, sadly. </p>
          <Box ml="1rem"><Button onClick={() => localEndTurn()} variant="contained" color="secondary">Okay</Button></Box>
        </Grid>);
    } else if (G.turnState === TurnState.MustMove) {
      return (
        <Grid container direction="row" wrap="nowrap"  justify="flex-start">
          <p>You got a {G.dieRoll}, go ahead and move!</p>
        </Grid>);
    } else {
      console.log("ugh", G);
      return (<p />);
    }
  }

  const colorForSpace = (id: number) => {
    if (id === -1 && G.middle !== null) {
      return COLORS[G.middle];
    }
    if (id < -1) {
      // 32 and -2 * ci below related
      // console.log("here with ", id);
      let q = Math.round(id / 32);
      let r = Math.abs(id - (q * 32)) - 1;
      q = Math.abs(q);
      // console.log("here with ", id,r, q, G.homes[q], COLORS[q]);
      if (G.homes[q] >= r) { return COLORS[q]; }
    }
    if (id >= 0 && id < G.board.length) {
      if (G.board[id] !== null) {
        const idx = G.board[id];
        if (idx !== null) {
          return COLORS[idx];
        }
      }
    }

    return WHITE;
  }

  const drawPlaces = (r: number, star_x: number, star_y: number, pr: number) => {
    const circles = [];
    const r2 = r / 2.0;
    const r4 = r2 / 2.0;
    const r6 = r2 / 3.0;

    circles.push(
      <circle cx="0" cy="0" r={pr} id={"p_" + -1} key={"k_" + -1} fill={colorForSpace(-1)} onClick={() => handleClick(-1)} stroke="black" strokeWidth="1"></circle>
    );

    for (let i = 0; i < 6; i++) {
      const ci = i * 16;
      const ci2 = -2 * ci;
      const angle = (i * 60) + 30;
      const rotate = `rotate(${angle},0,0)`;

      circles.push(

        <circle cx={star_x} cy={star_y + r2} r={pr} id={"p_" + (0 + ci)} key={"k_" + (0 + ci)} onClick={() => handleClick((0 + ci))} transform={rotate} fill={colorForSpace((0 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x} cy={star_y + r} r={pr} id={"p_" + (1 + ci)} key={"k_" + (1 + ci)} onClick={() => handleClick((1 + ci))} transform={rotate} fill={colorForSpace((1 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x} cy={star_y + r + r2} r={pr} id={"p_" + (2 + ci)} key={"k_" + (2 + ci)} onClick={() => handleClick((2 + ci))} transform={rotate} fill={colorForSpace((2 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x} cy={star_y + r + r} r={pr} id={"p_" + (3 + ci)} key={"k_" + (3 + ci)} onClick={() => handleClick((3 + ci))} transform={rotate} fill={colorForSpace((3 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x} cy={star_y + r + r + r2} r={pr} id={"p_" + (4 + ci)} key={"k_" + (4 + ci)} onClick={() => handleClick((4 + ci))} transform={rotate} fill={colorForSpace((4 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x - r2} cy={star_y + r + r + r2} r={pr} id={"p_" + (5 + ci)} key={"k_" + (5 + ci)} onClick={() => handleClick((5 + ci))} transform={rotate} fill={colorForSpace((5 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x - r2} cy={star_y + r + r} r={pr} id={"p_" + (6 + ci)} key={"k_" + (6 + ci)} onClick={() => handleClick((6 + ci))} transform={rotate} fill={colorForSpace((6 + ci))} stroke={COLORS[i]} strokeWidth="2" ></circle>,
        <circle cx={star_x - r2} cy={star_y + r + r2} r={pr} id={"p_" + (7 + ci)} key={"k_" + (7 + ci)} onClick={() => handleClick((7 + ci))} transform={rotate} fill={colorForSpace((7 + ci))} stroke={COLORS[i]} strokeWidth="2" ></circle>,
        <circle cx={star_x - r2} cy={star_y + r} r={pr} id={"p_" + (8 + ci)} key={"k_" + (8 + ci)} onClick={() => handleClick((8 + ci))} transform={rotate} fill={colorForSpace((8 + ci))} stroke={COLORS[i]} strokeWidth="2" ></circle>,
        <circle cx={star_x - r2} cy={star_y + r2} r={pr} id={"p_" + (9 + ci)} key={"k_" + (9 + ci)} onClick={() => handleClick((9 + ci))} transform={rotate} fill={colorForSpace((9 + ci))} stroke={COLORS[i]} strokeWidth="2" ></circle>,
        <circle cx={star_x - r} cy={star_y + r + r + r2} r={pr} id={"p_" + (10 + ci)} key={"k_" + (10 + ci)} onClick={() => handleClick((10 + ci))} transform={rotate} fill={colorForSpace((10 + ci))} stroke={COLORS[i]} strokeWidth="2" ></circle>,
        <circle cx={star_x - r} cy={star_y + r + r} r={pr} id={"p_" + (11 + ci)} key={"k_" + (11 + ci)} onClick={() => handleClick((11 + ci))} transform={rotate} fill={colorForSpace((11 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x - r} cy={star_y + r + r2} r={pr} id={"p_" + (12 + ci)} key={"k_" + (12 + ci)} onClick={() => handleClick((12 + ci))} transform={rotate} fill={colorForSpace((12 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x - r} cy={star_y + r} r={pr} id={"p_" + (13 + ci)} key={"k_" + (13 + ci)} onClick={() => handleClick((13 + ci))} transform={rotate} fill={colorForSpace((13 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x - r} cy={star_y + r2} r={pr} id={"p_" + (14 + ci)} key={"k_" + (14 + ci)} onClick={() => handleClick((14 + ci))} transform={rotate} fill={colorForSpace((14 + ci))} stroke="black" strokeWidth="1" ></circle>,
        <circle cx={star_x - r} cy={star_y} r={pr} id={"p_" + (15 + ci)} key={"k_" + (15 + ci)} onClick={() => handleClick((15 + ci))} transform={rotate} fill={colorForSpace((15 + ci))} stroke="black" strokeWidth="2" ></circle>,

        <circle cx={star_x + r4 + r6} cy={star_y + r + r2 + r4} r={pr} id={"p_" + (ci2 - 2)} key={"k_" + (ci2 - 2)} onClick={() => handleClick((ci2 - 2))} transform={rotate} fill={colorForSpace((ci2 - 2))} stroke={COLORS[i]} strokeWidth="1" ></circle>,
        <circle cx={star_x + r4 + r6} cy={star_y + r + r + r4} r={pr} id={"p_" + (ci2 - 3)} key={"k_" + (ci2 - 3)} onClick={() => handleClick((ci2 - 3))} transform={rotate} fill={colorForSpace((ci2 - 3))} stroke={COLORS[i]} strokeWidth="1" ></circle>,
        <circle cx={star_x + r2 + r6 + r6} cy={star_y + r + r2} r={pr} id={"p_" + (ci2 - 4)} key={"k_" + (ci2 - 4)} onClick={() => handleClick((ci2 - 4))} transform={rotate} fill={colorForSpace((ci2 - 4))} stroke={COLORS[i]} strokeWidth="1" ></circle>,
        <circle cx={star_x + r2 + r6 + r6} cy={star_y + r + r} r={pr} id={"p_" + (ci2 - 5)} key={"k_" + (ci2 - 5)} onClick={() => handleClick((ci2 - 5))} transform={rotate} fill={colorForSpace((ci2 - 5))} stroke={COLORS[i]} strokeWidth="1" ></circle>,
      );

    }
    return circles;
  }
  const drawBoard = () => {
    const r = CENTER_TO_STAR;
    const bw = BOARD_WIDTH;
    const star_x = Math.cos(Math.PI / 3) * r;
    const star_y = Math.sin(Math.PI / 3) * r;
    const pr = 10.5;

    const board_path = `m ${star_x} ${star_y} l 0 ${r * 2.5} l -${r} 0 l 0 -${r * 2.5}`;

    return (<div style={{ minWidth: 720 }}>
      <div style={{ textAlign: 'center' }}>
        <svg
          width="100%"
          height="100%"
          viewBox={[-(bw / 2), -(bw / 2), bw, bw].join(" ")}
          className="aggrxBoard"
          pointerEvents="visible"
        >
          <g strokeWidth="4" stroke="#cccccc">
            <path d={board_path} transform="rotate(30,0,0)" fill="transparent" stroke="gray" strokeWidth="1"></path>
            <path d={board_path} transform="rotate(90,0,0)" fill="transparent" stroke="gray" strokeWidth="1"></path>
            <path d={board_path} transform="rotate(150,0,0)" fill="transparent" stroke="gray" strokeWidth="1"></path>
            <path d={board_path} transform="rotate(210,0,0)" fill="transparent" stroke="gray" strokeWidth="1"></path>
            <path d={board_path} transform="rotate(270,0,0)" fill="transparent" stroke="gray" strokeWidth="1"></path>
            <path d={board_path} transform="rotate(330,0,0)" fill="transparent" stroke="gray" strokeWidth="1"></path>
            {drawPlaces(r, star_x, star_y, pr)}
          </g>
          <g></g>
        </svg>
      </div></div>);
  }



  const buildActivityReport = (G: GameState, ctx: GameContext) => {
    let accrue: string = "";
    if (G.activity.length > 0) {

      for (let item of G.activity) {
        if (item.playerID !== ctx.currentPlayer) { break; }
        let did = verbs[item.action];
        let value = item.value;

        switch (item.action) {

          case (Action.Rolled):
            value = "a " + value;
            break;

          case (Action.Moved):
            value = "";
            break;

          case (Action.CouldntMove):
            value = "";
            break;

          case (Action.Got):
            value = nameOfPlayer(G, ctx, value);
            break;

          case (Action.Struggled):
            value = "with their move";
            break;

          case (Action.Won):
            value = "";
            break;

          default:
            break;
        }

        accrue = `${nameOfPlayer(G, ctx, item.playerID)} ${did}${value.length > 0 ? " " + value : ""}.  ` + accrue;
      }

    }
    const activity = [];
    if (accrue.length === 0) {
      activity.push(<p key="activity1">Nothing of interest has happened yet on {currentColor}'s turn.</p>);
    } else {
      activity.push(<p key="activity2">{accrue}</p>);
    }
    return activity;
  }

  let flare = G.flare !== null ? <div><p>{G.flare}</p></div> : <div />;
  let whoseturn = ctx.currentPlayer === playerID ? <p>your turn</p> : <p>{currentColor}'s turn</p>;
  let winner = ctx.gameover ? ctx.gameover.winner ? (
    <div>
      <strong>{G.players[ctx.gameover.winner].name} is the winner!</strong>
    </div>
  ) : <div><strong>a draw!</strong></div> : null;

  let allActivity = buildActivityReport(G, ctx);
  let controls = buildControls();

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container direction="row" wrap="nowrap"
        alignItems="center"
        alignContent="center" >
        {drawBoard()}
        <Grid container
          direction="column"
          alignItems="flex-start"
          alignContent="flex-start" wrap="nowrap">
          <Grid container
            direction="column"
            alignItems="flex-start"
            alignContent="flex-start" wrap="nowrap" >
            <Typography display="block" variant="h6">hello {nameOfPlayer(G, ctx, playerID)}</Typography>
            {whoseturn}
            {flare}
            {controls}
            {winner}
          </Grid>
          <Grid container
            direction="column"
            alignItems="flex-start"
            alignContent="flex-start" wrap="nowrap">{allActivity}</Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Board;



