import { Player } from './player';

export enum Action {
    Rolled,
    Moved,
    CouldntMove,
    Got,
    Struggled,
    Won,
}

export interface WhoDidWhat {
    playerID: string,
    action: Action,
    value: string,
}

export enum TurnState {
    Start,
    MustRoll,
    Rolled,
    MustEnd,
    Ended,
    MustMove,
    Moved,
}

export interface GameState {
    board: Array<number|null>,
    middle: (number|null),
    homes: Array<number>,
    dieRoll: (number|null),
    activity: WhoDidWhat[],
    players: Player[],
    src: (number|null),
    dest: (number|null),
    flare: (string|null),
    turnState: TurnState
}