import { Action, GameState, TurnState, WhoDidWhat } from './gamestate';
import { GameContext } from './gamecontext';
import { Player } from './player';
import { move_data, center_move_data, start_move_data } from './move_data';
import { COLORS } from '../board/aggrx';

const playersToIndexes: Record<number, number[]> = {
  2: [0, 3],
  3: [0, 2, 4],
  4: [0, 1, 3, 4],
  5: [0, 1, 2, 3, 4],
  6: [0, 1, 2, 3, 4, 5]
};

function getPlayerIndex(G: GameState, ctx: GameContext): number {
  return G.players[ctx.playOrderPos].idx;
}
function playerIdOf(G: GameState, playerIndex: number): string {
  return G.players.filter( (value: Player, index: number, array: Player[]) => value.idx === playerIndex)[0].id;
}

function possibleMovesForPlayerPlaceRoll(playerIdx: number, place: number, dieRoll: number): string[] {
  let keyPrefix = place.toString() + ":" + ( Math.floor(place/16) === playerIdx ? "own" : "not") + ":" + dieRoll + ":";
  return Object.keys(move_data).filter((v) => {
    return v.startsWith(keyPrefix);
  });
}

/* given G.dieRoll, can any piece of currentPlayer move? */
function canMove(G: GameState, ctx: GameContext): boolean {
  if ( G.dieRoll ) {
    const currentPlayerIndex = getPlayerIndex(G, ctx);
    for ( const place of placesOfPlayer(G,ctx)) {
      if ( place === -1 && G.dieRoll === 1 ) {
        // if we're in the middle and we have a 1, we can move pretty much always.
        return true;
      }
      if ( place === -2 && ( G.dieRoll === 1 || G.dieRoll === 6 ) && G.board[currentPlayerIndex*16+10] !== currentPlayerIndex) {
        // if we have someone in home and we arent blocking ourselves
        return true;
      }

      OUTER:
      for ( let possibility of possibleMovesForPlayerPlaceRoll(currentPlayerIndex, place, G.dieRoll)) {
        for (let b of move_data[possibility]) {
          if (b === -1) {
            if (G.middle === currentPlayerIndex) {
              continue OUTER;
            }
          } else if (b !== -1) {
            if (G.board[b] === currentPlayerIndex) {
              continue OUTER;
            }
          }
        }
        // if we werent blocked by ourselves on any space to check
        // for this possibility, then yes we can move.
        return true;
      }
    }
  }
  return false; // ugh
}

export const Aggravation  = {
  name: 'aggravation',
  setup: function(ctx: GameContext, setupData?: any): any {
    const board: Array<number | null> = Array(6 * 16).fill(null);
    const middle: (number | null) = null;
    const homes: number[] = Array(6).fill(4);
    const dieRoll: (number | null) = null;
    const activity: WhoDidWhat[] = [];
    const players: Player[] = [];
    const src: (number | null) = null;
    const flare: (string | null) = null;
    const turnState: TurnState = TurnState.Start;

    let places: number[] = playersToIndexes[ctx.numPlayers];

    for (let p = 0; p < places.length; p++) {
      players.push({
        id: p.toString(),
        name: COLORS[places[p]],
        idx: places[p]
      });
    }
    return ({
      board,
      middle,
      homes,
      dieRoll,
      activity,
      players,
      src,
      flare,
      turnState,
    });
  },
  moves: {
    roll: function(G: GameState, ctx: GameContext): void {
      if ( G.turnState !== TurnState.MustRoll ) {
        console.log("roll(): we are not at TurnState.MustRoll!", G, ctx);
        return;
      }
      if ( ctx.random ) {
        G.dieRoll = ctx.random.D6();
        G.activity.unshift(
          {playerID: ctx.currentPlayer,action: Action.Rolled, value: G.dieRoll.toString()}
          );
        if ( canMove(G, ctx)) {
          G.turnState = TurnState.MustMove;
        } else {
          if ( G.dieRoll === 6 ) {
            G.turnState = TurnState.MustRoll;
          } else {
            G.turnState = TurnState.MustEnd;
          }
        }
      }
    },
    move: function(G: GameState, ctx: GameContext, srcid: number, destid: number): void{
      if ( G.turnState !== TurnState.MustMove ) {
        console.log("move(): we are not at TurnState.MustMove!", G, ctx);
        return;
      }
      if (canGetFromSrcToDestAsPlayer(srcid, destid, G, ctx)) {
        movePlayerToDest(srcid, destid, G, ctx);
        if (G.dieRoll !== 6) {
          if ( ctx.events && ctx.events.endTurn ) {
            G.turnState = TurnState.Ended;
            ctx.events.endTurn();
          }
        } else {
          G.turnState = TurnState.MustRoll;
        }
      }
      G.src = null;
      G.flare = null;
    },
    chooseSource: function(G: GameState, ctx: GameContext, srcid: number): void {
      if ( G.turnState !== TurnState.MustMove ) {
        console.log("chooseSource(): we are not at TurnState.MustMove!", G, ctx);
        return;
      }
      const currentPlayerIdx = getPlayerIndex(G,ctx);
      G.flare = null;
      if (placesOfPlayer(G, ctx).includes(srcid)) {
        G.src = srcid;
      } else if (srcid === -2 && G.homes[currentPlayerIdx] > 0) {
        G.src = srcid;
      } else {
        G.src = null;
        G.flare = "Try that move again";
        G.activity.unshift({playerID: ctx.currentPlayer,action: Action.Struggled, value: "again"});
      }
    },
  },
  turn: {
    // Called at the beginning of a turn.
    onBegin: function(G: GameState, ctx: GameContext): GameState {
      G.src = null;
      G.flare = null;
      G.dieRoll = null;
      G.turnState = TurnState.MustRoll;
      G.activity.length = 0;
      return G;
    },

  },
  endIf: (G: GameState, ctx: GameContext) => {
    if (isVictorious(G, ctx)) {
      // G.activity.unshift({playerID: ctx.currentPlayer, action: Action.Won, value: "again"});
      return { winner: ctx.currentPlayer };
    }
  },
};


function isVictorious(G: GameState, ctx: GameContext) {
  const currentPlayerIdx = getPlayerIndex(G,ctx);
  return (G.board[currentPlayerIdx * 16 + 6] === currentPlayerIdx &&
    G.board[currentPlayerIdx * 16 + 7] === currentPlayerIdx &&
    G.board[currentPlayerIdx * 16 + 8] === currentPlayerIdx &&
    G.board[currentPlayerIdx * 16 + 9] === currentPlayerIdx);
}


function movePlayerToDest(srcid: number, destid: number, G: GameState, ctx: GameContext) {
  const destValue = G.board[destid];
  const currentPlayerIdx = getPlayerIndex(G,ctx);

  G.activity.unshift({playerID: ctx.currentPlayer,action: Action.Moved, value: destid.toString()});

  if (srcid === -1) {
    if (destValue !== null) {
      G.homes[destValue] = G.homes[destValue] + 1;
      G.board[destid] = null;
      G.activity.unshift({playerID: ctx.currentPlayer,action: Action.Got, value: playerIdOf(G, destValue)});
    }
    G.middle = null;
    G.board[destid] = currentPlayerIdx;
  } else if (srcid === -2) {
    if (destValue !== null) {
      G.homes[destValue] = G.homes[destValue] + 1;
      G.board[destid] = null;
      G.activity.unshift({playerID: ctx.currentPlayer,action: Action.Got, value: playerIdOf(G, destValue)});
    }
    G.homes[currentPlayerIdx] = G.homes[currentPlayerIdx] - 1;
    G.board[destid] = currentPlayerIdx;
  } else {
    if (destid === -1) {
      if (G.middle !== null) {
        G.homes[G.middle] = G.homes[G.middle] + 1;
        G.activity.unshift({playerID: ctx.currentPlayer,action: Action.Got, value: playerIdOf(G, G.middle)});
        G.middle = null;
      }
      G.board[srcid] = null;
      G.middle = currentPlayerIdx;
    } else {
      if (destValue !== null) {
        G.homes[destValue] = G.homes[destValue] + 1;
        G.board[destid] = null;
        G.activity.unshift({playerID: ctx.currentPlayer,action: Action.Got, value: playerIdOf(G, destValue)});
      }
      G.board[srcid] = null;
      G.board[destid] = currentPlayerIdx;
    }
  }
}

function canGetFromSrcToDestAsPlayer(srcid: number, destid: number, G: GameState, ctx: GameContext) {
  const currentPlayerIdx = getPlayerIndex(G,ctx);
  const ownOrNot_key = Math.floor(srcid / 16) === currentPlayerIdx ? "own" : "not";
  const move_data_key = [srcid, ownOrNot_key, G.dieRoll, destid].join(':');
  const start_data_key = [currentPlayerIdx, destid].join(':');

  if (move_data_key in move_data) {
    for (let b of move_data[move_data_key]) {
      if (b === -1) {
        if (G.middle === currentPlayerIdx) {
          return false;
        }
      } else if (b !== -1) {
        if (G.board[b] === currentPlayerIdx) {
          return false;
        }
      }
    }
    return true;
  }

  if (srcid === -1 && destid.toString() in center_move_data && G.dieRoll === 1) {
    for (let b of center_move_data[destid.toString()]) {
      if (G.board[b] === currentPlayerIdx) {
        return false;
      }
    }
    return true;
  }

  if (srcid === -2 &&
    start_data_key in start_move_data &&
    G.homes[currentPlayerIdx] > 0
    && (G.dieRoll === 1 || G.dieRoll === 6)) {
    for (let b of start_move_data[start_data_key]) {
      if (G.board[b] === currentPlayerIdx) {
        return false;
      }
    }
    return true;
  }

  return false;
}


function placesOfPlayer(G: GameState, ctx: GameContext): number[] {
  const currentPlayerIdx = getPlayerIndex(G,ctx);
  var places = [];
  if (G.middle === currentPlayerIdx) {
    places.push(-1);
  }
  if (G.homes[currentPlayerIdx] > 0 ) {
    places.push(-2);
  }
  for (let i = 0; i < G.board.length; i++) {
    if (G.board[i] === currentPlayerIdx) {
      places.push(i);
    }
  }
  return places;
}




