import React from 'react';
import { RouteComponentProps } from 'react-router';
import request from 'superagent';
import { Container, Grid, Typography, CssBaseline, Select, MenuItem, Button, Box } from '@material-ui/core';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CopyToClipboard from 'react-copy-to-clipboard';
import { API_PORT } from '../constants';

interface MatchParams {
  name: string;
}

interface SetupProps extends RouteComponentProps<MatchParams> {
}

interface SetupState {
  players: number;
  gameID: string;
  secret: Record<number, string>;
  creating: boolean;
  created: boolean;
}

class Setup extends React.Component<SetupProps, SetupState> {

  apiBase: string;

  constructor(props: any) {
    super(props);
    this.state = {
      players: 2,
      gameID: "",
      secret: {
        0: "",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
      },
      creating: false,
      created: false
    };

    this.onPlayersUpdated = this.onPlayersUpdated.bind(this);
    this.createGame = this.createGame.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.apiBase = (process.env.NODE_ENV === 'production') ? '/api' : `${window.location.protocol}//${window.location.hostname}:${API_PORT}`;
  }

  async createGame() {
    this.setState({
      ...this.state,
      creating: true,
    });

    const r = await request
      .post(`${this.apiBase}/create`)
      .send({
        players: this.state.players,
      });

    const gameId = r.body.game;

    for (var i = 0; i < r.body.credentials.length; i++) {
      this.setState({
        ...this.state,
        secret: {
          ...this.state.secret,
          [i]: r.body.credentials[i],
        },
      });
    }

    this.setState({
      ...this.state,
      gameID: gameId,
      created: true,
    });
  }

  onPlayersUpdated(e: any) {
    if (e.target && e.target.value) {
      this.setState({
        ...this.state,
        players: parseInt(e.target.value),
      });
    }
  }

  isFormValid() {
    return true;
  }

  handleSubmit() {
    this.createGame();
  }

  render() {
    let createForm = <div />;
    let linkDisplay = <div />;
    if (!this.state.created) {
      createForm = (
        <React.Fragment>
          <Container>
            <Typography align="center" display="block" variant="h1">Aggravation</Typography>


            <form >
              <Typography align="center" display="block" variant="subtitle1">How Many Players?</Typography>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Select id="players" onChange={e => this.onPlayersUpdated(e)} value={this.state.players}>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                </Select>

                <Button variant="contained" color="primary" onClick={this.createGame} disabled={this.state.creating || !this.isFormValid()}>Proceed</Button>
              </Grid>
            </form>
            <Typography align="center" display="block" variant="subtitle1">
              Players will be able to join the game with the links that are generated after you proceed.
          </Typography>
          </Container>
        </React.Fragment>
      );
    } else {
      linkDisplay = (
        <React.Fragment>
          <Container>
            <Typography align="center" display="block" variant="h1">Aggravation</Typography>

            <Typography align="center" display="block" variant="subtitle1">Give one of these links to each player!</Typography>

            {Array(this.state.players).fill(0).map((v, i) =>
              <Box>
                <Typography align="center" display="block" variant="subtitle2">
                  {window.location.origin}/{this.state.gameID}/{i}/{this.state.secret[i]} <CopyToClipboard text={`${window.location.origin}/${this.state.gameID}/${i}/${this.state.secret[i]}`}><span><FileCopyOutlinedIcon/></span></CopyToClipboard>
                </Typography>
              </Box>
            )}

            <Typography align="center" display="block" variant="subtitle1">And away you go!</Typography>

          </Container>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <CssBaseline />
        {createForm}
        {linkDisplay}
      </React.Fragment>
    );
  }
}

export default Setup;